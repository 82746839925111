var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"tile":"","elevation":"0","height":"100%","min-height":"100%"}},[_c('v-form',{model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c('v-card-text',[(_vm.isUserAdmin)?_c('v-select',{staticClass:"pb-2",attrs:{"dense":"","hide-details":"","loading":_vm.isDataLoading,"items":_vm.nmoList,"label":_vm.getTranslation('patient.creationForm.formFieldTitles.nmo', '*'),"required":"","item-value":"id","item-text":"name","outlined":"","rules":[
          function () { return _vm.patient.nmoId > 0 ||
            _vm.getTranslation('patient.creationForm.errors.nmoRequired'); } ],"disabled":!_vm.isUserAdmin},model:{value:(_vm.patient.nmoId),callback:function ($$v) {_vm.$set(_vm.patient, "nmoId", $$v)},expression:"patient.nmoId"}}):_vm._e(),(!_vm.isUserDoctor)?_c('v-select',{staticClass:"pb-2",attrs:{"dense":"","hide-details":"","label":_vm.getTranslation('patient.creationForm.formFieldTitles.htc', '*'),"required":"","items":_vm.filteredHTCList,"item-text":"name","item-value":"id","outlined":"","disabled":_vm.filteredHTCList.length < 1,"loading":_vm.isDataLoading},model:{value:(_vm.patient.htcId),callback:function ($$v) {_vm.$set(_vm.patient, "htcId", $$v)},expression:"patient.htcId"}}):_vm._e(),_c('v-text-field',{ref:"patientId",staticClass:"pb-2",attrs:{"dense":"","hide-details":"","label":_vm.getTranslation(
            'patient.creationForm.formFieldTitles.patientId',
            '*'
          ),"required":"","outlined":"","disabled":!_vm.canUpdatePatientId,"rules":[
          function () { return !!_vm.patient.patientId ||
            _vm.getTranslation('patient.creationForm.errors.patientIdRequired'); },
          function () { return !_vm.patientExists ||
            _vm.getTranslation('patient.creationForm.errors.patientIdTaken'); } ]},on:{"keydown":function($event){return _vm.validatePatientUniqueness()}},model:{value:(_vm.patient.patientId),callback:function ($$v) {_vm.$set(_vm.patient, "patientId", $$v)},expression:"patient.patientId"}}),_c('v-text-field',{staticClass:"pb-2",attrs:{"dense":"","hide-details":"","label":_vm.getTranslation('patient.creationForm.formFieldTitles.wbdrId'),"outlined":""},model:{value:(_vm.patient.wbdrId),callback:function ($$v) {_vm.$set(_vm.patient, "wbdrId", $$v)},expression:"patient.wbdrId"}}),_c('v-menu',{ref:"menu",staticClass:"pb-2",attrs:{"dense":"","hide-details":"","close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"pb-2",attrs:{"dense":"","hide-details":"","append-icon":"","label":_vm.getTranslation(
                'patient.creationForm.formFieldTitles.dateOfBirth',
                '*'
              ),"rules":[],"readonly":"","outlined":""},model:{value:(_vm.dateOfBirth),callback:function ($$v) {_vm.dateOfBirth=$$v},expression:"dateOfBirth"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuDoB),callback:function ($$v) {_vm.menuDoB=$$v},expression:"menuDoB"}},[_c('v-date-picker',{ref:"picker",staticClass:"pb-2",attrs:{"dense":"","hide-details":"","show-adjacent-months":"","max":new Date(Date.now()).toISOString().substr(0, 10),"min":"1920-01-01"},on:{"input":function($event){_vm.menuDoB = false}},model:{value:(_vm.dateOfBirth),callback:function ($$v) {_vm.dateOfBirth=$$v},expression:"dateOfBirth"}})],1),_c('v-select',{staticClass:"pb-2",attrs:{"dense":"","hide-details":"","label":_vm.getTranslation(
            'patient.creationForm.formFieldTitles.diagnosis',
            '*'
          ),"items":_vm.diagnosisList,"loading":_vm.isDataLoading,"required":"","item-text":"name","item-value":"id","outlined":"","rules":[
          function () { return !!_vm.patient.diagnosisId ||
            _vm.$t('patient.creationForm.errors.diagnosisRequired'); } ]},model:{value:(_vm.patient.diagnosisId),callback:function ($$v) {_vm.$set(_vm.patient, "diagnosisId", $$v)},expression:"patient.diagnosisId"}}),_c('v-text-field',{staticClass:"pb-2",attrs:{"dense":"","hide-details":"","label":_vm.getTranslation(
            'patient.creationForm.formFieldTitles.physician',
            '*'
          ),"outlined":"","required":"","rules":[]},model:{value:(_vm.patient.physician),callback:function ($$v) {_vm.$set(_vm.patient, "physician", $$v)},expression:"patient.physician"}}),_c('v-switch',{attrs:{"label":_vm.$t('patient.creationForm.formFieldTitles.isActive')},model:{value:(_vm.patient.isActive),callback:function ($$v) {_vm.$set(_vm.patient, "isActive", $$v)},expression:"patient.isActive"}})],1),_c('div',{staticClass:"d-flex"},[_c('v-spacer'),_c('v-btn',{staticClass:"mr-4",attrs:{"color":"success","disabled":_vm.isSending || !_vm.isValid},on:{"click":function($event){return _vm.save()}}},[_vm._v(" "+_vm._s(_vm.$t("actions.save"))+" ")]),(_vm.isSending)?_c('v-progress-circular',{attrs:{"indeterminate":""}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }