<template>
  <v-card tile elevation="0" height="100%" min-height="100%">
    <v-form v-model="isValid">
      <v-card-text>
        <v-select
          dense
          hide-details=""
          class="pb-2"
          :loading="isDataLoading"
          :items="nmoList"
          :label="
            getTranslation('patient.creationForm.formFieldTitles.nmo', '*')
          "
          required
          item-value="id"
          item-text="name"
          outlined
          v-model="patient.nmoId"
          :rules="[
            () =>
              patient.nmoId > 0 ||
              getTranslation('patient.creationForm.errors.nmoRequired'),
          ]"
          :disabled="!isUserAdmin"
          v-if="isUserAdmin"
        ></v-select>
        <v-select
          dense
          hide-details=""
          class="pb-2"
          :label="
            getTranslation('patient.creationForm.formFieldTitles.htc', '*')
          "
          required
          :items="filteredHTCList"
          item-text="name"
          item-value="id"
          outlined
          v-model="patient.htcId"
          :disabled="filteredHTCList.length < 1"
          :loading="isDataLoading"
          v-if="!isUserDoctor"
        ></v-select>
        <v-text-field
          dense
          hide-details=""
          class="pb-2"
          ref="patientId"
          :label="
            getTranslation(
              'patient.creationForm.formFieldTitles.patientId',
              '*'
            )
          "
          required
          v-model="patient.patientId"
          outlined
          :disabled="!canUpdatePatientId"
          :rules="[
            () =>
              !!patient.patientId ||
              getTranslation('patient.creationForm.errors.patientIdRequired'),
            () =>
              !patientExists ||
              getTranslation('patient.creationForm.errors.patientIdTaken'),
          ]"
          @keydown="validatePatientUniqueness()"
        ></v-text-field>
        <v-text-field
          dense
          hide-details=""
          class="pb-2"
          :label="getTranslation('patient.creationForm.formFieldTitles.wbdrId')"
          v-model="patient.wbdrId"
          outlined
        ></v-text-field>
        <!-- <v-date-picker
          :label="$t('patient.creationForm.formFieldTitles.dateOfBirth')"
          v-model="patient.dateOfBirth"
          outlined
          required
          :disabled="patient.id > 0"
                ></v-date-picker>-->
        <v-menu
          dense
          hide-details=""
          class="pb-2"
          ref="menu"
          v-model="menuDoB"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              dense
              hide-details=""
              class="pb-2"
              append-icon
              v-model="dateOfBirth"
              :label="
                getTranslation(
                  'patient.creationForm.formFieldTitles.dateOfBirth',
                  '*'
                )
              "
              :rules="[]"
              readonly
              outlined
              v-on="on"
              v-bind="attrs"
            ></v-text-field>
          </template>
          <v-date-picker
            dense
            hide-details=""
            class="pb-2"
            ref="picker"
            show-adjacent-months
            v-model="dateOfBirth"
            @input="menuDoB = false"
            :max="new Date(Date.now()).toISOString().substr(0, 10)"
            min="1920-01-01"
          ></v-date-picker>
        </v-menu>
        <v-select
          dense
          hide-details=""
          class="pb-2"
          :label="
            getTranslation(
              'patient.creationForm.formFieldTitles.diagnosis',
              '*'
            )
          "
          :items="diagnosisList"
          :loading="isDataLoading"
          required
          v-model="patient.diagnosisId"
          item-text="name"
          item-value="id"
          outlined
          :rules="[
            () =>
              !!patient.diagnosisId ||
              $t('patient.creationForm.errors.diagnosisRequired'),
          ]"
        ></v-select>
        <v-text-field
          dense
          hide-details=""
          class="pb-2"
          :label="
            getTranslation(
              'patient.creationForm.formFieldTitles.physician',
              '*'
            )
          "
          outlined
          required
          v-model="patient.physician"
          :rules="[]"
        ></v-text-field>
        <v-switch
          :label="$t('patient.creationForm.formFieldTitles.isActive')"
          v-model="patient.isActive"
        ></v-switch>
      </v-card-text>
      <div class="d-flex">
        <v-spacer />
        <v-btn
          class="mr-4"
          @click="save()"
          color="success"
          :disabled="isSending || !isValid"
        >
          {{ $t("actions.save") }}
        </v-btn>
        <v-progress-circular
          v-if="isSending"
          indeterminate
        ></v-progress-circular>
      </div>
    </v-form>
  </v-card>
</template>
<script>
import axios from "axios";
export default {
  props: ["parameter"],
  data() {
    return {
      isValid: false,
      isSending: false,
      isShowContent: false,
      snackBarMessage: "SAVED",
      showSnackbarColor: "success",
      patient: { dateOfBirth: null, isActive: true },
      htcList: [],
      filteredHTCList: [],
      nmoList: [],
      diagnosisList: [],
      menuDoB: false,
      timeout: undefined,
      patientExists: false,
    };
  },
  computed: {
    canUpdatePatientId() {
      if (
        !(
          this.patient.id > 0 ||
          this.htcList.length < 1 ||
          this.nmoList.length < 1
        )
      ) {
        return true;
      } else if (!this.isUserDoctor) {
        return true;
      } else {
        return false;
      }
    },
    isUserAdmin() {
      return this.$store.getters.getIsAdmin;
    },
    isUserNMOAdmin() {
      return this.$store.getters.getIsNMOAdmin;
    },
    isUserDoctor() {
      return !this.isUserAdmin && !this.isUserNMOAdmin;
    },
    // htcId: {
    //   get() {
    //     return this.patient.htcId;
    //   },
    //   set(val) {
    //     this.patient.htcId = val.id;
    //   },
    // },
    dateOfBirth: {
      get: function () {
        if (this.patient.dateOfBirth == null) {
          return null;
        }
        var d = this.$moment(this.patient.dateOfBirth).format("YYYY-MM-DD");

        return d;
      },
      set: function (val) {
        this.patient.dateOfBirth = this.$moment(val).format("YYYY-MM-DD");
      },
    },
    nmoId() {
      return this.patient.nmoId;
    },
    patientId() {
      return this.patient.patientId;
    },
  },
  methods: {
    onSetTitle(title) {
      this.$emit("onSetTitle", title);
    },
    async loadPatient() {
      this.isDataLoading = true;
      await axios
        .get(
          `${this.$store.getters.getEnvironment}patient/${this.parameter.id}`
        )
        .then((res) => {
          this.patient = res.data;
          this.isDataLoading = false;
        })
        .catch((e) => {});
    },
    async loadNMOs() {
      this.isDataLoading = this.$store.state.loadingBarColor;
      await axios
        .get(`${this.$store.getters.getEnvironment}nmo?nameOnly=true`)
        .then((res) => {
          this.nmoList = res.data; //.map((x) => ({ name: x.name, value: x.id }));
          this.isDataLoading = false;
        })
        .catch((e) => {});
    },
    async loadHTCs() {
      this.isDataLoading = this.$store.state.loadingBarColor;
      await axios
        .get(`${this.$store.getters.getEnvironment}htc`)
        .then((res) => {
          this.htcList = res.data; //.map((x) => ({ text: x.name, value: x.id }));
          this.isDataLoading = false;
        })
        .catch((e) => {});
    },
    async loadDiagnosis() {
      this.isDataLoading = this.$store.state.loadingBarColor;
      await axios
        .get(`${this.$store.getters.getEnvironment}Diagnosis`)
        .then((res) => {
          var diagnosis = res.data;
          diagnosis = diagnosis.map((x) => {
            x.name = this.$t(`patient.diagnosis.${x.name}`);
            return x;
          });
          this.diagnosisList = diagnosis;
          this.isDataLoading = false;
        })
        .catch((e) => {});
    },
    save() {
      if (this.patient.id == null) this.insert();
      else this.update();
    },
    insert() {
      this.isSending = this.$store.state.loadingBarColor;
      axios
        .post(this.$store.getters.getEnvironment + "patient/", this.patient)
        .then((response) => {
          this.patient.id = response.data.data.id;
          this.isSending = false;
          this.$store.commit("setPatientKey");
          this.close();
        })
        .catch((e) => {});
    },
    update() {
      this.isSending = true;
      axios
        .put(
          this.$store.getters.getEnvironment + "patient/" + this.patient.id,
          this.patient
        )
        .then((response) => {
          this.patient.id = response.data.data.id;
          this.isSending = false;
          this.$store.commit("setPatientKey");
          this.close();
        })
        .catch((e) => {});
    },
    close() {
      this.$store.commit("hideModal");
    },
    getTranslation(translationPath, ...extra) {
      return `${this.$t(translationPath)}${extra.reduce(
        (acc, cur) => "" + acc + cur,
        ""
      )}`;
    },
    // saveDoB(date) {
    //   this.$refs.menuDoB.save(date);
    // },
    validatePatientUniqueness() {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        axios
          .get(
            `${this.$store.getters.getEnvironment}patient/search?patientId=${this.patient.patientId}&nmoId=${this.patient.nmoId}`
          )
          .then((res) => {
            this.patientExists = res.data.any;
            this.$refs["patientId"].validate();
          })
          .catch((e) => {
            this.patientExists = 0;
          });
      }, 500);
    },
    autoFillLocationIdentifiers() {
      const htcId = this.$store.getters.getHTCId;
      const nmoId = this.$store.getters.getNMOId;

      this.patient = { ...this.patient, htcId, nmoId };
    },
  },
  async created() {
    this.onSetTitle(this.$t("patient.view.edit"));

    await this.loadNMOs();
    await this.loadHTCs();
    await this.loadDiagnosis();
    if (this.parameter.id != -1) {
      this.loadPatient();
    } else {
      this.autoFillLocationIdentifiers();
    }
  },
  watch: {
    menuDoB(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
    nmoId(val) {
      this.filteredHTCList = this.htcList.filter((x) => {
        return x.nmoId == val;
      });
    },
  },
};
</script>